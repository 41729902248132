import { FormWrapper } from '../../../../../theme/default/base'
import { HtmlInput } from '../../form-inputs/Html'

export const PricingTiersForm = ({
  title = 'Find a plan that is right for your business',
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <HtmlInput
        value={title}
        name="title"
        label="Title"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}
