import React from 'react'
import { inject, observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { PAGEURL } from '/constants'
import { MembershipPlan } from '/components/subscription/MembershipPlan'

import {
  CommonPose
} from '/components/home/Animations'

export const PricingTiers = inject('store')(
  observer(({ store, title }) => {
    const router = useRouter()
    const { is_registered, is_paid } = store.getMembershipStatus()
    const gotoSignup = () => {
      if (store.isAuthenticated()) {
        router.push(PAGEURL.SUBSCRIBE)
      } else {
        router.push(PAGEURL.SIGNUP)
      }
    }
    return (
      <div className="pricing-block">
        <div className="background"></div>
        <div className="content">
          <div className="content-wrapper">
            <CommonPose>
              <MembershipPlan
                title={title}
                show_description={true}
                isRegistered={is_registered}
                isPaid={is_paid}
                selectFree={gotoSignup}
                selectPaid={gotoSignup}
              />
            </CommonPose>
          </div>
        </div>
      </div>
    )
  })
)
