import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import PlanTile from './PlanTile'

import { PricingTable as Table } from '../theme/plans/Plan'

@inject('store')
@observer
class MembershipPlan extends React.Component {
  render() {
    const plans = this.props.store.siteData.get('plans')
    const { eligible_for_trial } = this.props.store.sessionData
    const localStorage = typeof window !== 'undefined' && window.localStorage
    let headline = this.props.title || 'Choose a Plan'
    if (localStorage) {
      headline =
        localStorage.getItem('coupon_headline') ||
        this.props.title ||
        'Choose a Plan'
    }
    return (
      <div className="row">
        <div className="col-sm-12">
          <h1 className="text-center" dangerouslySetInnerHTML={{ __html: headline }} />
          <PricingTable
            eligibleForTrial={eligible_for_trial}
            showDescription={this.props.show_description}
            plans={plans}
            hideAction={this.props.hideAction || false}
          />
        </div>
      </div>
    )
  }
}

const JoinginOrgTier = (props) => (
  <PlanTile
    tag=""
    plan={0}
    title={'Joining ' + props.organization_name}
    monthlyPrice=""
    yearlyPrice=""
    description={
      'Sign up to become a member of the ' +
      props.organization_name +
      ' organization on Chalice'
    }
    {...props}
  />
)

JoinginOrgTier.propTypes = {
  organization_name: PropTypes.string
}

const PricingTable = ({
  plans,
  eligibleForTrial,
  showDescription,
  hideAction
}) => {
  const plans_display = []
  plans.forEach((plan) => {
    const props = {}
    plan.prices.forEach((price) => {
      if (price.interval === 'month') {
        props.monthly = price.amount / 100
        props.monthlyPrice = `$${price.amount / 100}`
        props.commitment_monthly = price.commitment
      } else {
        props.yearly = price.amount / 100
        props.yearlyPrice = `$${price.amount / 100}`
        props.commitment_yearly = price.commitment
      }
    })
    const features = plan.long_description.split('\n')
    plans_display.push(
      <PlanTile
        title={plan.name}
        tag="Membership"
        plan={plan}
        plan_id={plan.id}
        description={plan.description}
        features={features}
        showDescription={showDescription}
        showFeatures={false}
        hideAction={hideAction || false}
        trial={eligibleForTrial}
        frequency="monthly"
        {...props}
      />
    )
  })
  return <Table>{plans_display}</Table>
}

PricingTable.propTypes = {
  eligibleForTrial: PropTypes.bool,
  showDescription: PropTypes.bool,
  plans: PropTypes.array,
  hideAction: PropTypes.bool
}

const SelectedTier = ({ plan, price }) => {
  /*
  if (store?.subscription?.plan?.organization_name) {
    return <JoinginOrgTier hideAction={props.hideAction} {...props} />
  }
  */
  if (!plan) {
    return null
  }
  const pricing = {}
  if (plan.pricing_model == 'free') {
    pricing.monthlyPrice = 'Free'
    pricing.interval = 'month'
  } else {
    if (!price) {
      price = plan.prices[0]
    }
    pricing.interval = price.interval
    if (price.interval === 'month') {
      pricing.frequency = 'monthly'
      pricing.monthly = price.amount / 100
      pricing.monthlyPrice = `$${price.amount / 100}`
      pricing.commitment_monthly = price.commitment
    } else {
      pricing.frequency = 'yearly'
      pricing.yearly = price.amount / 100
      pricing.yearlyPrice = `$${price.amount / 100}`
      pricing.commitment_yearly = price.commitment
    }
  }
  const features = plan.long_description.split('\n')
  return (
    <PlanTile
      title={plan.name}
      tag="Membership"
      plan={plan}
      description={plan.long_description}
      features={features}
      showDescription={true}
      showFeatures={true}
      hideAction={true}
      frequency={pricing.frequency}
      {...pricing}
    />
  )
}

SelectedTier.propTypes = {
  plan: PropTypes.object.isRequired,
  price: PropTypes.object
}

MembershipPlan.propTypes = {
  title: PropTypes.any,
  isPaid: PropTypes.bool,
  isRegistered: PropTypes.bool,
  selectFree: PropTypes.func,
  selectPaid: PropTypes.func,
  show_description: PropTypes.bool,
  is_referral: PropTypes.bool,
  store: PropTypes.func,
  hideAction: PropTypes.bool
}

export { MembershipPlan, PricingTable, SelectedTier }
